/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBcTXrGaeJ4vYR55yLO2FsJNoR6Xt2uhUU",
  "appId": "1:28826322668:web:1ae41f4fa4ecef012647c9",
  "authDomain": "schooldog-i-hart-ga.firebaseapp.com",
  "measurementId": "G-QEJ8SMGKJJ",
  "messagingSenderId": "28826322668",
  "project": "schooldog-i-hart-ga",
  "projectId": "schooldog-i-hart-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-hart-ga.appspot.com"
}
